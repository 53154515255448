import { applyMiddleware, createStore, compose } from "redux";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "../reducers";
import * as localforage from "localforage";
import db from "../../config";

offlineConfig.persistOptions = { storage: localforage }; // store offline data in indexedDB

// overriding effect
const customConfig = {
  ...offlineConfig,
  effect: (effect: any, action: any) => {
    console.log("actions: ", action);
    return db.collection("experience").add(action.payload.content);
  },
};

const store = createStore(
  rootReducer,
  {},
  compose(applyMiddleware(thunk, logger), offline(customConfig))
);

export default store;
